.scroll-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    padding: 1rem;
    cursor: pointer;
    background-color: rgba(0, 0, 0, .32);
    backdrop-filter: saturate(100%) blur(10px);
    z-index: 1000;
}

.scroll-to-top svg {
    fill: var(--white);
}

/* BLOG */

.blog-container {
    min-width: 300px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
}

.blog-container h1 {
    font-weight: 400;
    font-size: 4rem;
    line-height: 5rem;
    font-weight: 700;
}

.blog-container>*,
.blog-card>* {
    color: var(--headers);
}

.blog-title {
    font: 700 4rem/5rem var(--black);
    letter-spacing: -3px;
    margin: 0;
}

.blog-text {
    font: 500 1.8rem/2.5rem var(--black);
    letter-spacing: 0px;
}

.blog-card {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border: 1px solid var(--dim-grey);
    border-radius: 15px;
    height: 400px;
    margin: 1rem;
    width: 350px;
    cursor: pointer;
    transition: transform 300ms ease;
    box-shadow:
        0.2px 0.4px 1.2px rgba(0, 0, 0, 0.02),
        0.6px 1.1px 2.9px rgba(0, 0, 0, 0.028),
        1.1px 2px 5.5px rgba(0, 0, 0, 0.035),
        2px 3.6px 9.8px rgba(0, 0, 0, 0.042),
        3.8px 6.7px 18.4px rgba(0, 0, 0, 0.05),
        9px 16px 44px rgba(0, 0, 0, 0.07);
    overflow: hidden;
}

.blog-card:hover {
    transform: translateY(-10px);
}

.blog-card-photo {
    width: 100%;
    height: 200px;
    object-fit: cover;
    overflow: hidden;
}

.blog-card-photo img {
    width: 100%;
}

.blog-card-content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.blog-card-content h4 {
    font-weight: 700;
    letter-spacing: -1px;
}

.blog-card-content .header {
    color: var(--blue);
    font: 400 1rem/2rem var(--black);
}

.blog-card-content .title {
    font: 700 1.7rem/1.8rem var(--black);
    letter-spacing: -1px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    height: 100px;
}

.blog-card-content .date {
    color: var(--grey);
    font: 500 0.9rem/0.9rem var(--black);
}


/* BLOG ARTICLE */
.blog-section-content {
    margin-top: 3rem;
    display: flex;
    flex-wrap: nowrap;
}

.sidebar {
    flex: 1;
}

.sidebar-wrapper {
    position: sticky;
    top: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.sidebar-wrapper .title {
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: -0.5px;
}

.sidebar_content {
    border-left: 1px solid var(--light-grey);
    margin: 0.5rem 0 3rem 0;
    padding: 0 0 0 1rem;
}

.sidebar_content.share {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
}

.sidebar_content ul.table_of_content {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sidebar_content ul.table_of_content li {
    padding: 0.5rem 0;
    font-size: 1rem;
    letter-spacing: -0.5px;
    color: var(--grey);
}

.sidebar_content ul.table_of_content li a:hover {
    color: var(--tldr-bg);
}

.blog-share-sticky {
    width: 40px;
    height: 200px;
}

.blog-content-block {
    flex: 3;
    margin-left: 30px;
}

.blog-img {
    width: 100%;
    max-height: 700px;
    object-fit: cover;
    border-radius: 20px;
    margin: 2rem 0 0 0;
}

.blog-img img {
    width: 100%;
}


.blog-content-block h2 {
    text-align: left;
    margin: 0 0 1rem 0;
}

.blog-content-block h3 {
    text-align: left;
    letter-spacing: -1px;
}

.blog-content-block p {
    /* font: 400 1.4rem var(--black); */
    font-size: 1.3rem;
    font-weight: 400;
    color: var(--black);
    /* line-height: 2.2rem; */
    line-height: calc(1ex / 0.28);
    text-align: left;
    letter-spacing: -0.8px;
}

.blog-content-block p a {
    text-decoration: none;
    color: var(--blue);
}

p.caption {
    font: 400 1rem/2rem var(--black);
    text-align: center;
    color: var(--grey);
    margin-bottom: 2rem;
    font-style: italic;
}

.blog-content-block .tldr {
    background-color: var(--tldr-bg);
    padding: 2rem;
    margin: 0 0 2rem 0;
    border-radius: 30px;
}

.blog-content-block .tldr h3,
.blog-content-block .tldr p,
.blog-content-block .tldr ul {
    color: var(--white);
}

.blog-content-block .tldr ul li {
    font-size: 1.2rem;
    padding: 0.8rem 0;
}

.blog-media-row {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 2rem 0;
}

.blog-media-row .media-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0.5rem;

}

.blog-media-row .media-block img {
    max-width: 180px;
    width: 100%;
    border-radius: 20px;
    margin: 1rem 0;
    -webkit-box-shadow: 5px 5px 8px -1px rgba(0, 0, 0, 0.44);
    box-shadow: 5px 5px 8px -1px rgba(0, 0, 0, 0.44);
}

.blog-media-row .media-block img.blog-img {
    max-width: 320px;
    width: 100%;
}

.blog-img-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2rem 0;
}

.blog-img-row>* {
    max-width: 50%;
    margin: 1rem;
}

.share-button {
    filter: grayscale(100%);
    opacity: 0.6;
    transition: all 300ms ease;
}

.share-button:hover {
    filter: grayscale(0%);
    opacity: 1;
}


@media screen and (max-width:1200px) {
    .blog-container {
        min-width: 300px;
        max-width: 992px;
        margin: 0 auto;
        padding: 0 4rem;
    }

}

@media screen and (max-width: 768px) {

    .blog-container {
        padding: 0 2rem;
    }

    .blog-container h1 {
        font-weight: 400;
        font-size: 2rem;
        line-height: 2.5rem;
        letter-spacing: -1px;
    }

    .blog-section-content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .blog-section-content .sidebar {
        width: 100%;
        flex: 1;
    }

    .blog-content-block {
        width: 100%;
        flex: 1;
        margin: 0;
    }

    .sidebar_content {
        margin: 0.5rem 0 1rem 0;
        padding: 0 0 0 1rem;
    }

    .blog-img-row {
        flex-direction: column;
    }

    .blog-img-row>* {
        max-width: 100%;
        margin: 1rem;
    }

    .blog-content-block h2 {
        font-size: 2rem;
        line-height: 1.3;
    }

    .blog-content-block h3 {
        font-size: 1.5rem;
        line-height: 1.2;
    }

    .blog-content-block p {
        font-size: 1.2rem;
        line-height: calc(1ex / 0.32);
        /* line-height: 1.5; */
        margin: calc(1ex / 0.32) 0;
    }

    .blog-content-block .tldr {
        padding: 1rem;
        margin-top: 1rem;
    }

}