.billing_options_row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
    /* flex-wrap: wrap; */
}

.billing_options_row .onoffswitch {
    margin: 0 2rem;
}

.discount {
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: 700;
    background-image: linear-gradient(to right, #3D2BFF, #19A4FE);
    /* background-color: var(--blue); */
    color: var(--white);
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    margin-left: 1rem;
    white-space: nowrap;
}

.card-pricing {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--card-background-color);
    align-items: flex-start;
    padding: 1rem;
    border-radius: 5px;
    margin: 1rem 1rem;
    min-width: 21rem;
    height: 100%;
    color: var(--black);
    -webkit-box-shadow: 5px 5px 21px -2px rgba(0, 0, 0, 0.49);
    box-shadow: 5px 5px 21px -2px rgba(0, 0, 0, 0.49);
}

.card-pricing-blue {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--card-background-color);
    align-items: flex-start;
    padding: 1rem;
    border-radius: 5px;
    margin: 1rem 1rem;
    width: 21rem;
    height: 100%;
    color: var(--black);
    -webkit-box-shadow: 5px 5px 21px -2px rgba(61, 43, 255, 0.49);
    box-shadow: 5px 5px 21px -2px rgba(61, 43, 255, 0.49);
}

.pricing-options {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.plan_name {
    font-size: 1.2rem;
    color: var(--blue);
    letter-spacing: -1px;
    font-weight: 500;
    margin-bottom: 2rem;
    text-align: center;
}

.plan_price {
    font-size: 4rem;
    font-weight: 500;
    letter-spacing: -5px;
}

.plan_price .currency {
    font-size: 3rem;
    font-weight: 300;
    margin-right: 1rem;
}

.plan_frequency {
    font-size: 1.4rem;
    color: var(--grey);
    font-weight: 300;
    margin-left: 0rem;
    letter-spacing: -1px;
}

.bullet-point {
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--dim-grey);
    margin-right: 0.5rem;
}

.bullet-point svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--blue);
}

.accounts_needed {
    font-size: 8rem;
    font-weight: 500;
    font-weight: 300;
    width: 200px;
    text-align: center;
    color: var(--reg-text);
}

.pricing_action {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.5rem;
    cursor: pointer;
}

.pricing_action svg {
    width: 2rem;
    fill: var(--blue);
}

.pricing-arrow {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /* margin-top: 2rem; */
}

.pricing-arrow img {
    width: 200px;
}

@media screen and (max-width: 1200px) {
    .pricing-options {
        display: flex;
        flex-direction: column-reverse;
    }

    .pricing-arrow {
        /* transform: rotateZ(90deg) translateX(100px) translateY(50px); */
        display: none;
    }

    .accounts_needed {
        font-size: 6rem;
        font-weight: 500;
        font-weight: 300;
        width: 150px;
        text-align: center;
        color: var(--reg-text);
    }
}


@media screen and (max-width: 500px) {
    .billing_options_row .onoffswitch {
        margin: 0 0.5rem;
    }
}