.faq_bloc {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--black);
    margin: 0;
    max-width: 800px;
}


.question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--reg-text);
    margin: 1rem 0;
    padding-top: 1rem;
    cursor: pointer;
}

.dropdown-button {
    height: 1.5rem;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    transition: all .8s cubic-bezier(.16, 1, .3, 1);
    cursor: pointer;
}

.active .dropdown-button {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
}

.dropdown-button svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--black);
}


.answer {
    max-height: 0;
    transition: max-height 0.3s ease-out;
    overflow: hidden;
    margin-top: 0;
    margin-bottom: 1rem;
    color: var(--black);
}

.active .answer {
    max-height: 500px;
    transition: max-height 0.5s ease-in;
}

.answer-img {
    max-height: 0px;
}

.active .answer-img {
    display: block;
    margin: auto;
    max-height: 300px;
    transition: max-height 0.5s ease-in;
}


/* FAQ Component */

.faq-index {
    background-color: var(--black);
    border-radius: 15px;
    margin: 0;
    padding: 2rem;
    color: var(--white);
    flex: 3;
}

.faq-answers {
    flex: 8;
}

.faq-index h4 {
    margin: 1rem 0;
}

ul.faq-list {
    margin: 0 0 2rem 0;
    line-height: 2.5;
}

ul.faq-list li {
    cursor: pointer;
}

p.answer a {
    cursor: pointer;
    color: var(--blue);
}

p.answer p {
    margin: 1rem 0;
}

@media screen and (max-width: 1200px) {
    .faq-answers {
        margin-top: 4rem;
    }
}