.docs_grid {
    display: grid;
    min-height: 100vh;
    grid-template-columns: 250px 1fr;
    grid-template-rows: 80px 1fr;
    grid-template-areas:
        "help_header help_header"
        "help_menu help_content";
}

.docs_grid_header {
    grid-area: help_header;
    border-bottom: 1px solid var(--dim-grey);
    background-color: var(--dimmer-grey);
}

.docs-header {
    display: flex;
    justify-content: space-between;
    height: 80px;
    align-items: center;
    padding: 1rem;
}

/* .docs-header .brand {
    height: 90px;
    display: flex;
    align-items: flex-start;
    padding: 1rem;
} */

.docs-header .brand svg {
    height: 3rem;
}

.docs-header .docs-header-title {
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase;
}

.docs_grid_menu {
    grid-area: help_menu;
    border-right: 1px solid var(--dim-grey);
    padding: 2rem 1rem;
}

.docs_grid_menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.docs_grid_menu ul li {
    margin-bottom: 1rem;
}

.docs_grid_menu ul li .list_title {
    color: var(--grey);
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.docs_grid_menu ul li .list_title svg {
    fill: var(--grey);
    height: 1rem;
    margin-right: 0.5rem;
    transition: all 0.3s ease-out;
}

.docs_grid_menu ul li .list_title.active {
    font-weight: 700;
}

.docs_grid_menu ul li .list_title.active svg {
    transform: rotateZ(90deg);
    /* transition: all 0.5s ease-in; */
}


.docs_grid_menu ul li ul {
    max-height: 0;
    list-style: none;
    margin: 0;
    padding-left: 2.5rem;
    transition: max-height 0.3s ease-out;
    overflow: hidden;
}

.docs_grid_menu ul li ul.active {
    max-height: 500px;
    transition: max-height 0.5s ease-in;
    list-style: none;
    margin: 0;
    padding-left: 2.5rem;
    overflow: hidden;
}

.docs_grid_menu ul li ul li {
    color: var(--grey);
    font-size: 0.9rem;
    font-weight: 400;
    cursor: pointer;
    margin: 0;
}

.docs_grid_menu ul li ul li:hover,
.docs_grid_menu ul li ul li.active {
    font-weight: 500;
}

.docs_grid_content {
    grid-area: help_content;
    display: flex;
    justify-content: center;
}


/* FORMATTING */

.docs_grid_content .content {
    width: 60rem;
    padding: 2rem;
}

.docs_grid_content .content h1 {
    font-size: 2.5rem;
    font-weight: 600;
    letter-spacing: -1px;
}

.docs_grid_content .content h2 {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: -1px;
    border-top: 1px solid var(--dim-grey);
    margin-bottom: 2rem;
}

.docs_grid_content .content h2 .number {
    color: var(--grey);
    margin-right: 1rem;
}

.docs_grid_content .content p {
    color: var(--dark-grey);
}