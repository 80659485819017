/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
    /* FONT */
    /* --font: 'Roboto', sans-serif; */
    --font: 'Poppins', sans-serif;
    --blog: "Helvetica Neue", sans-serif;



    /* COLORS */
    --bg-color: #FFFFFF;
    --blue: #3D2BFF;
    --light-blue: #19A4FE;
    --card-background-color: #f1f3f9;

    --white: #FFFFFF;
    --light-white: rgba(255, 255, 255, 0.7);
    --black: #222C37;
    --green: #5BC0BE;
    --red: #EB316F;
    --yellow: #fac823;


    /* GREYS */
    --dark-grey: #5f6469;
    --grey: #707A84;
    --light-grey: #C8CAD4;
    --dim-grey: #d8dae0;
    --dimmer-grey: #f3f4f5;

    /* BLOG */
    --tldr-bg: #19A4FE;
    /* --tldr-bg: #19a2fe89; */
}



/* MAIN BODY */
* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    width: 100%;
    height: 100%;
    font-size: 15px;
    background-color: var(--bg-color);
    /* This is for the blog sticky part but may be an issue on mobile */
    /* overflow-x: hidden;  */
}

a {
    text-decoration: none;
    color: inherit;
}

a.underlined_link {
    text-decoration: underline;
    color: var(--light-blue);
}

a.legal {
    color: var(--tldr-bg);
}

body {
    margin: 0 auto;
    padding: 0;
    overflow-x: hidden;
    display: block;
    min-height: 100vh;
    width: 100vw;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    /* background-color: red; */
}

.content_section {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
}


/* TEXT */

body,
p {
    color: var(--black);
    font: 400 1rem/1.625rem var(--font);
    margin: 0;
}


.p-large {
    font: 400 1.125rem/1.75rem var(--font);
}

.p-xlarge {
    font: 400 1.2rem/1.75rem var(--font);
}

.p-small {
    font: 400 0.875rem/1.5rem var(--font);
}

.p-min-margin {
    margin: 0.1rem 0;
}

.p-centered {
    text-align: center;
}

h1 {
    color: var(--black);
    font: 400 4rem/5.125rem var(--font);
    letter-spacing: -4px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

h2 {
    color: var(--headers);
    font: 500 3rem/4rem var(--font);
    letter-spacing: -2px;
    margin: 0;
    padding: 0;
}

h3 {
    color: var(--headers);
    font: 500 1.8rem/2.125rem var(--font);
    letter-spacing: -0.2px;
}

h4 {
    color: var(--headers);
    font: 500 1.375rem/1.75rem var(--font);
    letter-spacing: -0.1px;
}

h5 {
    color: var(--headers);
    font: 500 1.25rem/1.5rem var(--font);
    letter-spacing: -0.1px;
    margin: 0;
}

h6 {
    color: var(--headers);
    font: 500 1rem/1.275rem var(--font);
    letter-spacing: -0.1px;
    margin: 0;
}

.h-heading {
    margin-bottom: 1rem;
}

.h-min-margin {
    margin: 0;
}

.h3-heading {
    margin-top: 0;
}


.grad-blue {
    background-image: linear-gradient(to right, #3D2BFF, #19A4FE);
    /* background: -webkit-linear-gradient(var(--light-blue), var(--blue)); */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-centered {
    text-align: center;
}

.text-white {
    color: var(--white);
}

.text-light-white {
    color: var(--light-white);
}

.text-blue {
    color: var(--blue);
}

.text-red {
    color: var(--red);
}

.text-green {
    color: var(--green);
}

.text-bold {
    font-weight: 700;
}

/* FLEX */

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.row_nowrap {
    flex-wrap: nowrap !important;
}

.row.reverse_wrap {
    flex-wrap: wrap-reverse !important;
}

.row_flex_start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.row.centered {
    justify-content: center;
}

.row.row-100 {
    width: 100%;
}

.row.vcentered {
    align-items: center;
}

.row_spaced {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.row_spaced_around {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.col {
    display: flex;
    flex-direction: column;
    /* flex: 0 0 41.666667%; */
}

.col-maxed {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
}

.col-min {
    margin: 0;
    padding: 0;
}

.col.centered {
    align-items: center;
}

.col.vcentered {
    justify-content: center;
}

.column-flex-start {
    align-items: flex-start !important;
}



.flex-1 {
    flex: 1;
    /* min-width: 400px; */
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
    min-width: 300px;
}

.flex-4 {
    flex: 4;
}



/* MARGINS */


.margin-t1 {
    margin-top: 1rem;
}

.margin-t2 {
    margin-top: 2rem;
}

.margin-t4 {
    margin-top: 4rem;
}

.margin-t8 {
    margin-top: 8rem;
}

.margin-t10 {
    margin-top: 10rem;
}

.margin-b1 {
    margin-bottom: 1rem;
}

.margin-b2 {
    margin-bottom: 2rem;
}

.margin-s1 {
    margin-left: 1rem;
    margin-right: 1rem;
}

.margin-s2 {
    margin-left: 2rem;
    margin-right: 2rem;
}

.margin-s4 {
    margin-left: 4rem;
    margin-right: 4rem;
}

.margin-l1 {
    margin-left: 0.5rem;
}

.padding-t4 {
    padding-top: 4rem;
}

.padding-t8 {
    padding-top: 8rem;
}

.padding-s2 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.padding-s4 {
    padding-left: 4rem;
    padding-right: 4rem;
}

.padding-b2 {
    padding-bottom: 2rem;
}

.padding-b4 {
    padding-bottom: 4rem;
}

.full_vh {
    height: 80vh;
    display: flex;
    align-items: center;
}

.width-33 {
    width: 33vw;
}

.width-800 {
    min-width: 300px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}



/* IMAGES */

.img-render {
    image-rendering: -moz-crisp-edges;
    /* Firefox */
    image-rendering: -o-crisp-edges;
    /* Opera */
    image-rendering: -webkit-optimize-contrast;
    /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    /* IE (non-standard property) */
}

.img-fluid {
    width: 100%;
}

.img-min {
    min-width: 300px;
}

.img-rounded {
    border-radius: 30px;
}

.img-shadow {
    box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
    -webkit-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
    -moz-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
}

.img-bg-blue {
    padding: 3rem 2rem;
    /* background: linear-gradient(90deg, var(--blue-alpha) 225px, #ffffff 0, #ffffff 100%); */
    background-color: var(--blue-alpha);
}

.img-bg-red {
    padding: 3rem 2rem;
    background-color: var(--red-alpha);
}

.img-bg-green {
    padding: 3rem 2rem;
    background-color: var(--green-alpha);
}

.img-200 {
    max-width: 200px;
}

.img-600 {
    max-width: 600px;
}

.svg-green {
    fill: var(--green) !important;
}

.svg-blue {
    fill: var(--light-blue) !important;
}

.svg-red {
    fill: var(--red) !important;
}

.svg-grey {
    fill: var(--grey) !important;
}

.svg-2 {
    height: 1.5rem;
}

/* INPUTS */
label {
    color: var(--reg-text);
    font-size: 1rem;
    font-weight: 500;
    /* margin: 0.3rem 0.5rem; */
    /* display: flex;
    align-items: center;
    flex-wrap: wrap; */
}

input[type="search"],
input[type="text"],
input[type="text"]:focus,
input[type="text"]:active,
input[type="number"],
input[type="number"]:focus,
input[type="number"]:active,
input[type="date"],
input[type="date"]:focus,
input[type="date"]:active,
input[type="password"],
input[type="password"]:focus,
input[type="password"]:active,
input[type="datetime-local"],
input[type="datetime-local"]:focus,
input[type="datetime-local"]:active,
input[type="email"],
input[type="email"]:focus,
input[type="email"]:active,
input[type="tel"],
input[type="tel"]:focus,
input[type="tel"]:active,
select {
    background-color: var(--dim-grey);
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: var(--black);
    outline: none;
    /* font-size: clamp(1rem, 1vw + 1rem, 1.5rem); */
    width: fit-content;
    min-width: 20rem;
}

input[type="time"],
input[type="time"]:focus,
input[type="time"]:active {
    background-color: var(--dim-grey);
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: var(--black);
    outline: none;
    /* font-size: clamp(1rem, 1vw + 1rem, 1.5rem); */
    width: fit-content;
    min-width: 10rem;
}

select.time_select,
.time_select {
    background-color: var(--dim-grey);
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: var(--black);
    outline: none;
    /* font-size: clamp(1rem, 1vw + 1rem, 1.5rem); */
    width: fit-content;
    min-width: 4rem;
}

.tz-select {
    width: 15rem;
}

/* SECTIONS */

.hero {
    min-height: 90vh;
    display: flex;
    align-items: center;
    /* height: 90vh; */
}

.herotxt {
    position: relative;
}

.herotxt .arrow-hero {
    position: absolute;
    right: 0;
    top: 0;
    width: 196px;
    height: 41px;
}

.blog-hero {
    margin: 2rem 0;
}


.cta-block,
.cta-block-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
}

.cta-block-footer {
    align-items: center;
}

.cta {
    cursor: pointer;
    padding: 0.8rem 1.2rem;
    background-image: linear-gradient(to right, #3D2BFF, #19A4FE);
    color: var(--white);
    font-weight: 400;
    display: flex;
    line-height: 1.1;
    align-items: center;
    transition: all 0.3s ease;
    box-shadow:
        1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
        5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
        10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
        19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
        46px 51px 80px rgba(0, 0, 0, 0.07);

}

.cta::after {
    content: ' ';
    position: relative;
    top: 0px;
    width: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.90533 2.96967C8.61244 2.67678 8.13756 2.67678 7.84467 2.96967C7.55178 3.26256 7.55178 3.73744 7.84467 4.03033L11.0643 7.25H3.125C2.71079 7.25 2.375 7.58579 2.375 8C2.375 8.41421 2.71079 8.75 3.125 8.75H11.0643L7.84467 11.9697C7.55178 12.2626 7.55178 12.7374 7.84467 13.0303C8.13756 13.3232 8.61244 13.3232 8.90533 13.0303L13.4053 8.53033C13.6982 8.23744 13.6982 7.76256 13.4053 7.46967L8.90533 2.96967Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
    height: 16px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 16px;
    line-height: 100%;
    vertical-align: middle;
    display: inline-block;
    margin-left: 4px;
    transition: transform 0.25s ease-in-out;
}

.cta:hover::after {
    transform: translateX(3px);
}

.cta-block .cta_sub {
    font-weight: 500;
    font-size: 0.8rem;
    color: var(--black);
    margin-left: 1rem;
    line-height: 1.2;
}

.cta-block .cta_demo {
    margin-top: 1rem;
    font-size: 0.8rem;
    color: var(--black);
}

.cta-block-footer .cta_demo {
    margin-top: 1rem;
    font-size: 0.8rem;
    color: var(--black);
}

.cta-block-footer .cta_demo.demo_white {
    color: var(--white);
}

.cta-block .cta_demo a {
    cursor: pointer;
    border-bottom: 1px dotted var(--black);
}

.cta-block-footer .cta_demo.demo_white a {
    border-bottom: 1px dotted var(--white);
}


.dividers {
    border-top: 1px solid var(--light-grey);
    border-bottom: 1px solid var(--light-grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
}

.main-video {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 3rem;
    background: linear-gradient(0deg, var(--black) 50%, var(--white) 50%);
}

.main-video video {
    width: 100%;
    -webkit-box-shadow: 0px 0px 37px 9px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 37px 9px rgba(0, 0, 0, 0.2);
}

.features_section {
    background: linear-gradient(0deg, var(--black) 50%, var(--white) 50%);
    margin: 0;
}

.testimonials_section {
    margin: 0;
}

.black-bg {
    background-color: var(--black);
}

.grey-bg {
    background-color: var(--dim-grey);
    border-radius: 10px;
}

.learn_more {
    font-weight: 500;
}

.learn_more.learn_more_white {
    color: var(--white);
}

.learn_more::after {
    content: ' ';
    position: relative;
    top: 0px;
    width: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.90533 2.96967C8.61244 2.67678 8.13756 2.67678 7.84467 2.96967C7.55178 3.26256 7.55178 3.73744 7.84467 4.03033L11.0643 7.25H3.125C2.71079 7.25 2.375 7.58579 2.375 8C2.375 8.41421 2.71079 8.75 3.125 8.75H11.0643L7.84467 11.9697C7.55178 12.2626 7.55178 12.7374 7.84467 13.0303C8.13756 13.3232 8.61244 13.3232 8.90533 13.0303L13.4053 8.53033C13.6982 8.23744 13.6982 7.76256 13.4053 7.46967L8.90533 2.96967Z' fill='%23222C37'/%3E%3C/svg%3E%0A");
    height: 16px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 16px;
    line-height: 100%;
    vertical-align: middle;
    display: inline-block;
    margin-left: 4px;
    transition: transform 0.25s ease-in-out;
}

.learn_more.learn_more_white::after {
    content: ' ';
    position: relative;
    top: 0px;
    width: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.90533 2.96967C8.61244 2.67678 8.13756 2.67678 7.84467 2.96967C7.55178 3.26256 7.55178 3.73744 7.84467 4.03033L11.0643 7.25H3.125C2.71079 7.25 2.375 7.58579 2.375 8C2.375 8.41421 2.71079 8.75 3.125 8.75H11.0643L7.84467 11.9697C7.55178 12.2626 7.55178 12.7374 7.84467 13.0303C8.13756 13.3232 8.61244 13.3232 8.90533 13.0303L13.4053 8.53033C13.6982 8.23744 13.6982 7.76256 13.4053 7.46967L8.90533 2.96967Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
    height: 16px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 16px;
    line-height: 100%;
    vertical-align: middle;
    display: inline-block;
    margin-left: 4px;
    transition: transform 0.25s ease-in-out;
}

.learn_more:hover::after {
    transform: translateX(3px);
}


.staticcard {
    width: 20rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 2rem;
    /* background-color: #f8f8f8; */
}

.staticcard svg {
    fill: var(--black);
    width: 40px;
}

.staticcard.green svg {
    fill: #20C4CB;
    width: 40px;
}

.staticcard h4 {
    margin: 0.5rem 0;
}

.staticcard p {
    text-align: left;
}

.staticcard .learn_more {
    font: 500 1rem/0.875rem var(--font);
}

.carousel_wrapper {
    max-width: 700px;
}

.card {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--card-background-color);
    padding: 2rem;
    border-radius: 5px;
    min-width: 20rem;
    max-width: 90vw;
    height: fit-content;
    color: var(--main-color);
    transition: all 0.3s ease;
    opacity: 1;
    box-shadow:
        1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
        5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
        10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
        19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
        46px 51px 80px rgba(0, 0, 0, 0.07);
}




/* REVIEWS */

.review {
    display: flex;
    flex-direction: column;
    width: 400px;
    align-items: flex-start;
    padding: 2rem;
    /* background-color: var(--white); */
    border-radius: 10px;
    margin: 0 1rem;
    color: var(--white);
    /* box-shadow:
        1.3px 1.4px 2.2px rgba(255, 255, 255, 0.02),
        3.1px 3.4px 5.3px rgba(255, 255, 255, 0.028),
        5.8px 6.4px 10px rgba(255, 255, 255, 0.035),
        10.3px 11.4px 17.9px rgba(255, 255, 255, 0.042),
        19.2px 21.3px 33.4px rgba(255, 255, 255, 0.05),
        46px 51px 80px rgba(255, 255, 255, 0.07); */

}

.review .quote {
    font-size: 1rem;
    font-style: italic;
}

.review .stars {
    display: flex;
    justify-content: flex-start;
    margin: 1rem 0 0 0;
    font-size: 1rem;
    font-weight: 700;
}

.review .stars .review-date {
    color: var(--grey);
    font-weight: 500;
    font-style: italic;
    font-size: 0.8rem;
    margin-left: 0.5rem;
}

.review .stars svg {
    width: 1.5rem;
    fill: var(--yellow);
}


@media screen and (max-width: 1200px) {


    .hero {
        margin-top: 4rem;
        flex-direction: column;
    }

    .herotxt {
        margin-top: 4rem;
    }

    .herotxt .arrow-hero {
        display: none;
    }

    .heroimg {
        min-width: 300px;
        margin-top: 4rem;
    }

    .blog-hero {
        margin: 8rem auto !important;
    }

    .content_section {
        padding: 0 2rem;
        margin: 2rem 0;
    }

    .margin-0 {
        margin: 0 !important;
    }

    /* h1 {
        font: 400 3rem/4.125rem var(--font);
    } */
}