ul.kanban_dashboard {
    list-style: none;
    width: fit-content;
}

ul.kanban_dashboard li {
    line-height: 1.4;
    border-bottom: 1px dotted var(--dim-grey);
    padding: 1rem 0;
}

ul.kanban_dashboard .kanban_task {
    color: var(--black);
    font-size: 1.2rem;
    font-weight: 500;
}


ul.kanban_dashboard .kanban_description {
    color: var(--grey);
    font-size: 1rem;
}

ul.kanban_dashboard .kanban_status {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}

ul.kanban_dashboard .kanban_status .kanban_status_block {
    color: var(--black);
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    margin: 0 1rem;
}

ul.kanban_dashboard .kanban_status .kanban_status_block svg {
    margin-right: 0.5rem;
    width: 1.5rem;
    fill: var(--green);
}

ul.kanban_dashboard .kanban_button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.2rem;
    border: 1px solid var(--light-grey);
    border-radius: 3px;
    /* width: 80px; */
    height: 30px;
    padding: 0 5px;
    cursor: pointer;
    color: var(--grey);
}

ul.kanban_dashboard .kanban_button svg {
    fill: var(--light-grey);
    margin-right: 3px;
}

ul.kanban_dashboard .kanban_button:hover {
    color: var(--black);
}

ul.kanban_dashboard .kanban_button:hover svg {
    fill: var(--green);
}

ul.kanban_dashboard .kanban_button.upvoted {
    border-color: var(--green);
    color: var(--black);
}

ul.kanban_dashboard .kanban_button.upvoted svg {
    fill: var(--green);
}