.carousel-root {
  margin: 0;
  margin-top: 3%;
}

.carousel .slide {
  color: black;
  height: 100%;
}

.carousel .slide img {
  width: 139px;
  border-radius: 50%;
  /* border: 8px solid var(--light-blue); */
  border: 8px solid transparent;
  /* border-image: linear-gradient(to right, #3D2BFF, #19A4FE) 1; */
  background:
    linear-gradient(var(--black), var(--black)) padding-box,
    linear-gradient(to right, #3D2BFF, #19A4FE) border-box;
}


.myCarousel {
  background: var(--white);
  margin-top: -4rem;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  /* border: none; */
  border-radius: 10px;
  height: 356px;
  border: 1px solid #ddd;
  margin-bottom: 6rem;
  box-shadow:
    1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
    3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
    5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
    10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
    19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
    46px 51px 80px rgba(0, 0, 0, 0.07);
}

.myCarousel .stars {
  display: flex;
  justify-content: center;
  margin: 1rem 0 0 0;
}

.myCarousel .stars svg {
  width: 2rem;
  fill: var(--blue);
}

.carousel .control-dots {
  padding-left: 5px;
  outline: 0;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
  margin: 2rem auto 0 auto;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: var(--grey);
  /* color: #787878; */
  font-size: 14px;
}

.myCarousel p {
  /* font-weight: 100; */
  line-height: 29px;
  color: #222;
  font-size: 1.2rem;
  /* font-family: sans-serif; */
  /* max-height: 67px; */
  margin-top: 2rem;
  position: relative;
}

.myCarousel p:before {
  /* content: "“";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100; */
  font-family: Georgia, serif;
  content: '\201C';
  color: var(--grey);
  font-size: 7.5em;
  font-weight: 700;
  opacity: .3;
  position: absolute;
  top: 2rem;
  left: -.2em;
  text-shadow: none;
  z-index: 0;
}

.myCarousel p:after {
  content: "”";
  /* color: #aaa; */
  color: var(--grey);
  font-size: 26px;
  font-family: Georgia, serif;
  /* font-family: monospace; */
  font-weight: 100;
  line-height: 0;
}


.carousel .control-dots .dot {
  box-shadow: none;
  background: var(--white);
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: #000;
  height: 50px;
  position: absolute;
  top: 35%;
}

/* .App {
  text-align: center;
} */

@media only screen and (max-width: 934px) {

  .carousel-root {
    outline: 0;
    width: 93vw;
    margin: auto;
  }

  .carousel.carousel-slider .control-arrow {
    display: none;
  }

  .myCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 10%;
    padding-right: 10%;
    border: 1px solid #ddd;
    height: 350px;
  }

  .carousel .slide img {
    width: 24%;
    border-radius: 50%;
  }
}