.day_button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dim-grey);
    /* border: 1px solid var(--green); */
    color: var(--white);
    padding: 1rem;
    font-size: 1rem;
    font-weight: 500;
    outline: none;
    width: 80px;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 0.5rem;
    line-height: 1;
}

.day_button.selected {
    background-color: var(--blue);
}


.call-block {
    display: flex;
    /* flex-direction: column; */
    width: fit-content;
}

.call {
    cursor: pointer;
    padding: 0.8rem 1.2rem;
    background-image: linear-gradient(to right, #3D2BFF, #19A4FE);
    color: var(--white);
    font-weight: 400;
    display: flex;
    line-height: 1.1;
    align-items: center;
    transition: all 0.3s ease;
    box-shadow:
        1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
        5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
        10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
        19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
        46px 51px 80px rgba(0, 0, 0, 0.07);

}

.submitted {
    padding: 0.8rem 1.2rem;
    background-image: linear-gradient(to right, var(--green), #19A4FE);
    color: var(--white);
    font-weight: 400;
    display: flex;
    line-height: 1.1;
    align-items: center;
    transition: all 0.3s ease;
}