.banner {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: var(--green);
    font-size: 1.1rem;
    text-transform: uppercase;
    color: var(--white);
    padding: 0.5rem 0;
}

header {
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0rem;
    /* position: fixed; */
    /* top: 0; */
    /* left: 0; */
    width: 100vw;
    margin: 0 auto;
    max-width: 1200px;
    z-index: 2;
    /* background-color: red; */
    background-color: var(--white);
    align-items: center;
}

.brand a {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

.brand a svg {
    height: 3rem;
    /* margin-right: 0.5rem; */
}


.menu_wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}

.menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0px;
    color: var(--black);
    text-transform: capitalize;
}

.menu a {
    margin: 0 1.5rem;
    transition: all 0.2s ease;
}

.menu a:hover {
    opacity: 1;
}

.header_cta {
    padding: 0.2rem 1.1rem;
    white-space: nowrap;
    border-radius: 5px;
    /* border: 2px solid var(--blue); */
    color: var(--black);
    font-weight: 400;
    margin: 0 2rem;
    transition: all 0.3s ease;
    cursor: pointer;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #3D2BFF, #19A4FE) 1;
}

.header_cta::after {
    content: ' ';
    position: relative;
    top: 0px;
    width: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.90533 2.96967C8.61244 2.67678 8.13756 2.67678 7.84467 2.96967C7.55178 3.26256 7.55178 3.73744 7.84467 4.03033L11.0643 7.25H3.125C2.71079 7.25 2.375 7.58579 2.375 8C2.375 8.41421 2.71079 8.75 3.125 8.75H11.0643L7.84467 11.9697C7.55178 12.2626 7.55178 12.7374 7.84467 13.0303C8.13756 13.3232 8.61244 13.3232 8.90533 13.0303L13.4053 8.53033C13.6982 8.23744 13.6982 7.76256 13.4053 7.46967L8.90533 2.96967Z' fill='%233D2BFF'/%3E%3C/svg%3E%0A");
    height: 16px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 16px;
    line-height: 100%;
    vertical-align: middle;
    display: inline-block;
    margin-left: 4px;
    transition: transform 0.25s ease-in-out;
}

.header_cta:hover::after {
    transform: translateX(3px);
}

.lg_wrapper_mobile {
    display: none;
}

.lg_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    cursor: pointer;
    position: relative;
    margin: 0 1.5rem;
}

.lg_wrapper svg {
    fill: var(--black);
    height: 1.2rem;
}

.lg_wrapper .flag {
    display: flex;
    align-items: center;
}

.lg_wrapper .flag img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.lg_menu {
    position: absolute;
    top: 2rem;
    right: 0rem;
    background-color: var(--white);
    color: var(--reg-text);
    padding: 0.5rem 1rem;
    border-radius: 5px;
    width: fit-content;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    transition: transform 100ms ease;
    transform-origin: top;
    opacity: 1;
}



.lg_menu.inactive {
    transform: scale(0);
    opacity: 0;
}

.features_menu.inactive {
    transform: scale(0);
    opacity: 0;
}

.features_menu {
    position: absolute;
    top: 2rem;
    right: 0rem;
    background-color: var(--white);
    color: var(--reg-text);
    padding: 0.5rem 1rem;
    border-radius: 5px;
    width: fit-content;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    transition: transform 100ms ease;
    transform-origin: top;
    opacity: 1;
    z-index: 10;
}

.features_menu .feature {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 1rem;
}

.features_menu .feature:hover {
    color: var(--light-blue);
}

.dropdown-item {
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--black);
    margin: 0;
}

.menu_burger {
    display: none;
    cursor: pointer;
}

.menu_burger_bar {
    width: 100%;
    height: .2rem;
    /* background-color: #333; */
    background-image: linear-gradient(to right, #3D2BFF, #19A4FE);
    border-radius: .4rem;
    transform-origin: center center;
    transition: .3s cubic-bezier(.16, 1, .3, 1);
}

@media screen and (max-width: 1200px) {
    header {
        padding: 1rem 2rem;
        height: 80px;
        display: flex;
        justify-content: space-between;
        /* padding: 1rem 0rem; */
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        margin: 0 auto;
        z-index: 2;
        background-color: var(--white);
        align-items: center;
    }

    .banner {
        position: fixed;
        top: 80px;
        left: 0;
        display: flex;
        justify-content: center;
        width: 100vw;
        background-color: var(--green);
        font-size: 0.8rem;
        text-transform: uppercase;
        color: var(--white);
        padding: 0.5rem 0;
        z-index: 2;
    }

    .menu_active {
        display: flex;
        transition: .3s cubic-bezier(.16, 1, .3, 1);
        transform: translateY(0);
        opacity: 1;
    }

    .menu_active .brand {
        fill: var(--white);
    }

    .menu_wrapper {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        padding: 10% 0;
        position: fixed;
        top: 0;
        left: 0;
        background-color: var(--black);
        transform: translateY(-100%);
        opacity: 0;
    }

    .menu_active .menu_wrapper {
        display: flex;
        transition: .3s cubic-bezier(.16, 1, .3, 1);
        transform: translateY(0);
        opacity: 1;
        z-index: 2;
    }

    .menu_wrapper .menu {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }

    .menu_block {
        display: none;
    }

    .menu_wrapper .lg_wrapper {
        display: none;
    }

    .lg_wrapper_mobile {
        display: flex;
        justify-content: space-around;
        color: var(--white);
        font-weight: 700;
        width: 50%;
        margin-top: 8rem;
    }

    .lg_wrapper_mobile .flag {
        font-size: 1.5rem;
        color: var(--white);
        cursor: pointer;
        font-weight: 300;
        display: flex;
        align-items: center;
    }

    .lg_wrapper_mobile .flag img {
        width: 2rem;
        margin-right: 0.5rem;
    }

    /* p.language {
        font-size: 1.5rem;
        color: var(--white);
    } */

    .navlink {
        padding: 1.8rem 0;
        /* border-bottom: dotted 1px var(--grey); */
        color: var(--white);
        font-size: 1.3rem;
        font-weight: 500;
    }

    .header_cta {
        font-size: 1.3rem;
        font-weight: 500;
        padding: 1rem;
        color: var(--white);
    }



    .menu_burger {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 1.8rem;
        height: 1.4rem;
        position: relative;
        z-index: 10;
    }

    /* .menu_burger_bar {
        background-color: var(--black);
    }

    .menu_active .menu_burger_bar {
        background-color: var(--black);
    } */

    .menu_active .menu_burger_bar-1 {
        transform: rotate(-45deg) translateX(-.85rem);
    }

    .menu_active .menu_burger_bar-2 {
        opacity: 0;
    }

    .menu_active .menu_burger_bar-3 {
        transform: rotate(45deg) translateX(-.85rem);
    }
}