.pricing_grid {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.pricing_grid_row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 2rem;
    border-radius: 10px;
}

.pricing_grid_row.grey {
    background-color: var(--dimmer-grey);
}

.pricing_grid_row.options {
    border: 2px solid var(--light-blue);
    padding: 2rem 1rem;

}

.pricing_grid_row .billing_options {
    display: flex;
    align-items: center;
}

.pricing_grid_row .option_slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
}

.pricing_grid_row .option_slider .slider {
    width: 300px;
    display: flex;
    align-items: center;
}

.pricing_grid_row.highlighted {
    background-image: linear-gradient(to right, #3D2BFF, #19A4FE);
    color: var(--white);
    font-weight: 500;
    font-size: 1.3rem;
    padding: 0.5rem;
}

.pricing_grid_description_block {
    width: 300px;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: var(--black);
}

.pricing_grid_description_block.title {
    color: var(--white);
    font-size: 1.3rem;
}

.pricing_grid_description_block svg {
    height: 1.4rem;
    fill: var(--grey);
    margin-right: 0.5rem;
}

.pricing_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid var(--light-grey); */
    /* width: 300px; */
    border-radius: 10px;
    padding: 1rem 0.5rem;
    position: relative;
    background-color: var(--dimmer-grey);
}

.pricing_header.special {
    background-image: linear-gradient(to top, #3D2BFF, #19A4FE);
    color: var(--white)
}

.pricing_header .x_mas_logo {
    position: absolute;
    top: -2rem;
    right: -3rem;
    transform: rotateZ(10deg);
}

.pricing_header .x_mas_logo img {
    height: 5rem;
    width: 100%;
}

.pricing_header .pricing_header_limited {
    position: absolute;
    top: -20px;
    font-size: 0.7rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: var(--red);
}

.pricing_header .pricing_header_plan_name {
    font-size: 1.5rem;
    text-transform: uppercase;
}

.pricing_header .pricing_header_price {
    display: flex;
    align-items: flex-start;
    font-size: 5rem;
    line-height: 5rem;
}

.pricing_header .pricing_header_price .currency {
    font-size: 2rem;
}

.pricing_grid_data {
    display: flex;
    justify-content: center;
    margin: 0 1rem;
}

.pricing_grid_data.sm_icons img {
    height: 1rem;
    margin: 0 0.1rem;
    filter: grayscale(100%);
    opacity: 0.8;
}

.pricing_grid_data.main {
    font-size: 1.2rem;
    font-weight: 600;
}

.pricing_grid_data svg {
    fill: var(--green);
    height: 2rem;
}

.pricing_grid_data.red svg {
    fill: var(--red);
    height: 2rem;
}

.pricing_grid_data.yearly {
    font-size: 0.8rem;
    color: var(--grey);
    font-style: italic;
}