.legal_wrapper {
    display: flex;
    flex-direction: column;
    max-width: 90rem;
    margin: auto;
    padding: 8rem 2rem;
    line-height: 1.6;
}

.legal_wrapper a {
    color: var(--tldr-bg);
}

.legal_wrapper h1,
.legal_wrapper h2 {
    color: var(--blue);
    font-size: clamp(1.5rem, 7vw, 2rem);
}

.legal_wrapper h3 {
    font-size: clamp(1rem, 6vw, 1.5rem);
    color: var(--grey);
}

.legal_wrapper p {
    color: var(--reg-text);
}

.legal_wrapper strong {
    margin-right: 0.5rem;
}