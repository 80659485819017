footer {
    width: 100vw;
    min-height: 750px;
    background-color: var(--black);
    margin-top: 8rem;
    padding-top: 6rem;
}

.footer_title {
    font: 400 2.5rem/2.625rem var(--font);
    color: var(--white);
    max-width: 500px;
    text-align: center;
    letter-spacing: -1px;
}

.footer_logo {
    height: 2.5rem;
}

.footer_menu {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.footer_block {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.footer_block a {
    color: var(--light-white);
    font: 300 1rem/1.9rem var(--font);
}

.footer_block a:hover {
    color: var(--white);
}

.footer_sm_icon {
    opacity: 0.9;
    filter: brightness(0) invert(1);
    height: 2rem;
    width: 2rem;
    margin: 1rem 1rem 0 0;
}

.copyright {
    margin-top: 6rem;
    text-align: left;
    font-size: 0.8rem;
    color: var(--light-white);
    display: flex;
}

.copyright a {
    color: var(--light-white);
    margin: 0 0.5rem;
}

.copyright a:hover {
    color: var(--white);
}

@media screen and (max-width: 1200px) {
    .footer_menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 2rem 0;
    }

    .footer_block {
        margin: 1rem 0;
    }

    .copyright {
        flex-direction: column;
    }

    .copyright a {
        margin: 1rem 0;
    }
}